import CustomLink from '../components/links/CustomLink'
import styles from './styles/Pages.module.scss'

export default function Policy() {
  return (
    <div className={styles.policy}>
      <div>
        <CustomLink to={'/'} className="back">
          На Главную
        </CustomLink>
      </div>

      <div className={styles.title}>Политика конфиденциальности</div>

      <div className={styles.policy_content}>
        <p>
          Настоящая Политика конфиденциальности регулирует порядок обработки и
          использования персональных и иных данных «ОсОО ЧОП "Хиллс» (далее —
          Администратор). <br /> <br /> Передавая Администратору персональные и
          иные данные посредством Приложения, Пользователь подтверждает свое
          согласие на использование указанных данных на условиях, изложенных в
          настоящей Политике конфиденциальности.
          <br /> <br /> Если Пользователь не согласен с условиями настоящей
          Политики конфиденциальности, он обязан прекратить использование
          Приложения. Безусловным акцептом настоящей Политики конфиденциальности
          является начало использования Приложения Пользователем.
        </p>
        <h3>1. ТЕРМИНЫ</h3>
        <div>
          <p>
            1.1. Приложение – мобильное приложение, расположенное в магазине
            приложений App Store. Все исключительные права на Приложение и его
            отдельные элементы (включая программное обеспечение, дизайн)
            принадлежат Администратору в полном объеме. Передача исключительных
            прав Пользователю не является предметом настоящей Политики
            конфиденциальности.
          </p>
          <p>1.2. Пользователь — лицо использующее Приложение.</p>
          <p>
            1.3. Законодательство — действующее законодательство Российской
            Федерации.
          </p>
          <p>
            1.3. Законодательство — действующее законодательство Российской
            Федерации.
          </p>
          <p>
            1.4. Персональные данные — любая информация, относящаяся к прямо или
            косвенно к Пользователю, которые он предоставляет о себе
            самостоятельно при Регистрации или в процессе использования
            функционала Приложения.
          </p>
          <p>
            1.5. Данные — иные данные о Пользователе (не входящие в понятие
            Персональных данных).
          </p>
          <p>
            1.6. Регистрация — заполнение Пользователем Регистрационной формы,
            расположенной в Приложении, путем указания необходимых сведений и
            выбора Логина и пароля.
          </p>
          <p>
            1.7. Регистрационная форма — форма, расположенная в Приложении,
            которую Пользователь должен заполнить для прохождения Регистрации в
            Приложении.
          </p>
          <p>
            1.8. Услуга(и) — услуги, предоставляемые Администратором в рамках
            Приложения.
          </p>
        </div>

        <h3>2. СБОР И ОБРАБОТКА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
        <div>
          <p>
            2.1. Администратор собирает и хранит только те Персональные данные,
            которые необходимы для оказания Услуг Администратором и
            взаимодействия с Пользователем.
          </p>
          <p>
            2.2. Персональные данные могут использоваться в следующих целях:
          </p>
          <p>2.2.1 оказание Услуг Пользователю;</p>
          <p>2.2.2 идентификация Пользователя;</p>
          <p>2.2.3 взаимодействие с Пользователем;</p>
          <p>
            2.2.4 направление Пользователю рекламных материалов, информации и
            запросов;
          </p>
          <p>2.2.5 проведение статистических и иных исследований;</p>
          <p>
            2.2.6 мониторинг операций Пользователя в целях предотвращения
            мошенничества, противоправных ставок, отмывания денег.
          </p>
          <p>2.3. Администратор в том числе обрабатывает следующие данные:</p>
          <p>2.3.1 фамилия, имя и отчество;</p>
          <p>2.3.2 адрес электронной почты;</p>
          <p>2.3.3 номер мобильного телефона.</p>
          <p>
            2.4. Пользователю запрещается указывать в Приложении персональные
            данные третьих лиц, без предварительного получения их согласия,
            посредством подписания дополнительного документа: Согласия на
            обработку персональных данных.
          </p>
        </div>

        <h3>3. ПОРЯДОК ОБРАБОТКИ ПЕРСОНАЛЬНЫХ И ИНЫХ ДАННЫХ</h3>
        <div>
          <p>
            3.1. Администратор обязуется использовать Персональные данные в
            соответствии с Законом Республики Узбекистан от 2 июля 2019 года №
            ЗРУ-547 «О персональных данных» (с изменениями и дополнениями по
            состоянию на 14.01.2021 г.) . и правилами, установленными
            Администратором.
          </p>
          <p>
            3.2. В отношении Персональных данных и иных Данных Пользователя
            сохраняется их конфиденциальность, кроме случаев, когда указанные
            данные являются общедоступными.
          </p>
          <p>
            3.3. Администратор имеет право сохранять архивную копию Персональных
            данных и Данных, в том числе после удаления аккаунта Пользователя до
            момента отзыва согласия на обработку персональных данных путем
            направления письменного заявления Пользователя Администратору;
          </p>
          <p>
            3.4. Администратор имеет право передавать Персональные данные и
            Данные Пользователя без согласия Пользователя следующим лицам:
          </p>
          <p>
            3.4.1 государственным органам, в том числе органам дознания и
            следствия, и органам местного самоуправления по их мотивированному
            запросу;
          </p>
          <p>
            3.4.2 партнерам Администратора в целях оказания услуг Пользователю;
          </p>
          <p>
            3.4.3 в иных случаях, прямо предусмотренных действующим
            законодательством Республики Узбекистан.
          </p>
          <p>
            3.5. Администратор имеет право передавать Персональные данные и
            Данные третьим лицам, не указанным в п. 3.4. настоящей Политики
            конфиденциальности, в следующих случаях:
          </p>
          <p>3.5.1 Пользователь выразил свое согласие на такие действия;</p>
          <p>
            3.5.2 передача необходима в рамках использования Пользователем
            Приложения или оказания Услуг Пользователю;
          </p>
          <p>
            3.6. Администратор осуществляет автоматизированную обработку
            Персональных данных и Данных.
          </p>
        </div>

        <h3>4. ИЗМЕНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
        <div>
          <p>
            4.1. Пользователь может в любой момент изменить (обновить,
            дополнить) Персональные данные посредством Личного кабинета либо
            путем направления письменного заявления Администратору.
          </p>
          <p>
            4.2. Пользователь в любой момент имеет право удалить Персональные
            данные.
          </p>
          <p>
            4.3. Пользователь гарантирует, что все Персональные данные являются
            актуальными и не относятся к третьим лицам.
          </p>
        </div>

        <h3>5. ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ</h3>
        <div>
          <p>
            5.1. Администратор осуществляет надлежащую защиту Персональных и
            иных данных в соответствии с Законодательством и принимает
            необходимые и достаточные организационные и технические меры для
            защиты Персональных данных.
          </p>
          <p>
            5.2. Применяемые меры защиты в том числе позволяют защитить
            Персональные данные от неправомерного или случайного доступа,
            уничтожения, изменения, блокирования, копирования, распространения,
            а также от иных неправомерных действий с ними третьих лиц.
          </p>
        </div>

        <h3>6. ПЕРСОНАЛЬНЫЕ ДАННЫЕ ТРЕТЬИХ ЛИЦ, ИСПОЛЬЗУЕМЫЕ ПОЛЬЗОВАТЕЛЯМИ</h3>
        <div>
          <p>
            6.1. Используя Приложение, Пользователь имеет право направлять
            Администратору данные третьих лиц для последующего использования в
            целях оказания услуг данным лицам, после получения предварительного
            согласия таких лиц.
          </p>
          <p>
            6.2. Пользователь обязуется получить согласие субъекта персональных
            данных путем подписания Согласия на обработку персональных данных.
            Пользователь несет самостоятельную ответственность перед субъектом
            персональных данных, за передачу персональных данных последнего для
            обработки Администратору, без предоставления на то согласия субъекта
            персональных данных.
          </p>
          <p>
            6.3. Администратор и Пользователь обязуются предпринять необходимые
            меры для обеспечения сохранности персональных данных третьих лиц,
            занесенных Пользователем.
          </p>
        </div>

        <h3>7. ИНЫЕ ПОЛОЖЕНИЯ</h3>
        <div>
          <p>
            7.1. К настоящей Политике конфиденциальности и отношениям между
            Пользователем и Администратором, возникающим в связи с применением
            Политики конфиденциальности, подлежит применению право Российской
            Федерации.
          </p>
          <p>
            7.2. Все возможные споры, вытекающие из настоящего Соглашения,
            подлежат разрешению в соответствии с действующим законодательством
            по месту регистрации Администратора. Перед обращением в суд
            Пользователь должен соблюсти обязательный досудебный порядок и
            направить Администратору соответствующую претензию в письменном
            виде. Срок ответа на претензию составляет 30 (тридцать) рабочих
            дней.
          </p>
          <p>
            7.3. Если по тем или иным причинам одно или несколько положений
            Политики конфиденциальности будут признаны недействительными или не
            имеющими юридической силы, это не оказывает влияния на
            действительность или применимость остальных положений Политики
            конфиденциальности.
          </p>
          <p>
            7.4. Администратор имеет право в любой момент изменять Политику
            конфиденциальности (полностью или в части) в одностороннем порядке
            без предварительного согласования с Пользователем. Все изменения
            вступают в силу на следующий день после размещения в Приложении.
          </p>
          <p>
            7.5. Пользователь обязуется самостоятельно следить за изменениями
            Политики конфиденциальности путем ознакомления с актуальной
            редакцией.
          </p>
        </div>
      </div>
    </div>
  )
}
